import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/shared/seo'
import Layout from '../components/shared/layout'
import Hero from '../components/shared/hero'

import { Grid } from 'theme-ui'
import Table from '../components/tabs/table'
import ArticleNav from '../components/shared/article-nav'

class ArtistPageTemplate extends React.Component {
  render() {
    const post = get(this.props, 'data.contentfulArtistPage')
    const previous = get(this.props, 'data.previous')
    const next = get(this.props, 'data.next')
    const tabs = post.tab_page

    return (
      <Layout location={this.props.location}>
        <Seo
          title={post.title}
          description={post.description.childMarkdownRemark.excerpt}
          image={`http:${post.heroImage.resize.src}`}
        />
        <Hero
          image={post.heroImage?.gatsbyImageData}
          title={post.title}
          content={post.body?.body}
        />
        <Grid sx={{p: 4, m: 'auto', maxWidth: '50rem', gridGap: 4}}>
          {/* <span className={styles.meta}>
            {post.author?.name} &middot;{' '}
            <time dateTime={post.rawDate}>{post.publishDate}</time> –{' '}
            {post.body?.childMarkdownRemark?.timeToRead} minute read
          </span> */}
            {/* <Box sx={{ m: "auto", maxWidth: "40rem" }}
              dangerouslySetInnerHTML={{
                __html: post.body?.childMarkdownRemark?.html,
              }}
            /> */}
            {/* <Tags tags={post.tags} /> */}
          <Table tabs={tabs} artistPage={true} />
          <ArticleNav next={next} previous={previous} route="artists" />
        </Grid>
      </Layout>
    )
  }
}

export default ArtistPageTemplate

export const pageQuery = graphql`
  query ArtistPageBySlug(
    $slug: String!
    $previousPageSlug: String
    $nextPageSlug: String
  ) {
    contentfulArtistPage(slug: { eq: $slug }) {
      slug
      title
      author {
        name
      }
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
        }
        body
      }
      tags
      description {
        childMarkdownRemark {
          excerpt
        }
      }
      tab_page {
        tuning
        title
        slug
        difficulty
      }
    }
    previous: contentfulArtistPage(slug: { eq: $previousPageSlug }) {
      slug
      title
    }
    next: contentfulArtistPage(slug: { eq: $nextPageSlug }) {
      slug
      title
    }
  }
`
