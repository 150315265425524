import React from 'react'
import { Flex, Box, Heading } from 'theme-ui'
import InternalLink from './internal-link'

const ArticleNav = ({ previous, next, route }) => {
  if (previous || next)
    return (
      <Flex
        sx={{
          justifyContent: 'space-between',
          flexWrap: 'wrap',
        }}
        as="nav"
      >
        <Box>
          {previous && (
            <InternalLink to={`/${route}/${previous.slug}`} rel="prev">
              <Heading variant="styles.h3">← Previous</Heading>
              {previous.title}
            </InternalLink>
          )}
        </Box>
        <Box sx={{ textAlign: 'right' }}>
          {next && (
            <InternalLink to={`/${route}/${next.slug}`} rel="next">
              <Heading variant="styles.h3">Next →</Heading>
              {next.title}
            </InternalLink>
          )}
        </Box>
      </Flex>
    )
}

export default ArticleNav
